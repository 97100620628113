

.black-body {
    margin: 0; 
    height: 100vh; 
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--grey-whiteBackGround-7);
  }

  .black-label{
    display: flex;
    height: 15%;
    width: 100%;
    justify-content: center; /* 水平居中 */
    align-items:end
  
}

.black-labelTitle{
    margin-bottom: 3px;
    font-family: "Meiryo";
    text-shadow: -1px 0 3px #217aa2, 0 1px 3px #217aa2, 1px 0 3px #217aa2, 0 -1px 3px #217aa2;
    font-weight: 700;
    font-size: 30px;
    color: var(--white);

}

  .black-main{
    height: 70%;
    width: 80%;

    position:relative;
    margin: 1%;
    display: flex;
    flex-direction: column;

    padding: 4px 12px;
    border: 1px solid var(--grey-blackBackGround-7);
    box-shadow: 1px 1px 2px var(--grey-blackBackGround-7), -1px -1px 2px var(--grey-blackBackGround-7);
  }

.black-saveButton{
  height: 100%;
  width: 50%;
  margin: 1%;
  border: 1px solid var(--grey-blackBackGround-7);
  box-shadow: 1px 1px 2px var(--grey-blackBackGround-7), -1px -1px 2px var(--grey-blackBackGround-7);

  font-family: "Meiryo";
  font-weight: 700;
  font-size: 20px;
  color: var(--grey-whiteBackGround-7);
}
.black-saveButton:active {
  box-shadow: inset 1px 1px 2px var(--grey-blackBackGround-7), inset -1px -1px 2px var(--grey-blackBackGround-7);
  background-color: var(--grey-blackBackGround-7); /* 假定按下去时背景色变深 */
}

.black-return{
  height: 6%;
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.black-includeLabel{
    position:relative;
    left: 2%;
    font-family: "Meiryo";
    font-weight: 300;
    font-size: 20;
    font-weight: 700;
    color: var(--grey-blackBackGround-1);
}

.black-titleArea{
    top: 5%;
    left: 1%;
    position:relative;
    font-family: "Meiryo";
    font-weight: 300;
    font-size: 20px;
    font-weight: 700;
    color: var(--grey-blackBackGround-1);
}

.black-filters{
    position: relative;
    top: 5%;
    height: 71%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.black-filter{
    width: fit-content;
    position: relative;
    margin: 1%;
    display: inline-block;
    padding: 4px 12px;
    border: 1px solid var(--grey-blackBackGround-7);
    box-shadow: 1px 1px 2px var(--grey-blackBackGround-7), -1px -1px 2px var(--grey-blackBackGround-7);
    background-color: var(--grey-whiteBackGround-7);
    color: var(--grey-blackBackGround-1);
    font-weight: 500;
}


 /* 整个滚动条 */
 ::-webkit-scrollbar {
    width: 12px;               /* 滚动条宽度 */
    background-color: var(--grey-blackBackGround-95); /* 滚动条背景颜色 */
  }
  
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #888;    /* 滑块背景颜色 */
    border-radius: 6px;        /* 滑块圆角大小 */
    border: 2px solid var(--grey-blackBackGround-95); /* 滑块边框 */
  }
  
  /* 滚动条滑块悬停样式 */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;    /* 滑块悬停时的背景色 */
  }
  
  /* 滚动条轨道样式 */
  ::-webkit-scrollbar-track {
    background-color: var(--grey-blackBackGround-95); /* 轨道的背景颜色 */
    border-radius: 6px;        /* 轨道圆角大小 */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); /* 轨道内阴影 */
  }
  
  /* 滚动条轨道区域，未使用 */
  ::-webkit-scrollbar-track-piece {
    background-color: none;    /* 未使用滚动条轨道颜色 */
  }
  
  /* 滚动条按钮（上下箭头） */
  ::-webkit-scrollbar-button {
    display: none;             /* 通常不需要显示滚动条按钮 */
  }