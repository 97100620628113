

.about-body {
    margin: 0; 
    height: 100vh; 
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--grey-whiteBackGround-7);
  }





.about-labelTitle{
    margin-bottom: 3px;
    font-family: "Meiryo";
    text-shadow: -1px 0 3px #217aa2, 0 1px 3px #217aa2, 1px 0 3px #217aa2, 0 -1px 3px #217aa2;
    font-weight: 700;
    font-size: 30px;
    color: var(--white);

}

  .about-main{
    height: 85%;
    width: 80%;

    position:relative;
    margin: 1%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    padding: 4px 12px;
    border: 1px solid var(--grey-blackBackGround-7);
    box-shadow: 1px 1px 2px var(--grey-blackBackGround-7), -1px -1px 2px var(--grey-blackBackGround-7);
  }


.about-return{
  height: 30%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.about-titleArea{
    top: 5%;
    left: 1%;
    position:relative;
    font-family: "Meiryo";
    font-weight: 300;
    font-size: 20px;
    font-weight: 700;
    color: var(--grey-blackBackGround-1);
    display: flex;
    justify-content: center;
}

.about-returnArrow{
  position: absolute;
  top: 10px;
  left: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 27px;
  padding: 4px 12px;
  height: 50px;
  color: var(--grey-blackBackGround-2);
  transform: scale(1.5);
}

.heart{
  color: var(--red);
}

 /* 整个滚动条 */
 ::-webkit-scrollbar {
    width: 12px;               /* 滚动条宽度 */
    background-color: var(--grey-blackBackGround-95); /* 滚动条背景颜色 */
  }
  
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #888;    /* 滑块背景颜色 */
    border-radius: 6px;        /* 滑块圆角大小 */
    border: 2px solid var(--grey-blackBackGround-95); /* 滑块边框 */
  }
  
  /* 滚动条滑块悬停样式 */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;    /* 滑块悬停时的背景色 */
  }
  
  /* 滚动条轨道样式 */
  ::-webkit-scrollbar-track {
    background-color: var(--grey-blackBackGround-95); /* 轨道的背景颜色 */
    border-radius: 6px;        /* 轨道圆角大小 */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); /* 轨道内阴影 */
  }
  
  /* 滚动条轨道区域，未使用 */
  ::-webkit-scrollbar-track-piece {
    background-color: none;    /* 未使用滚动条轨道颜色 */
  }
  
  /* 滚动条按钮（上下箭头） */
  ::-webkit-scrollbar-button {
    display: none;             /* 通常不需要显示滚动条按钮 */
  }