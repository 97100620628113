.about-body {
    margin: 0; 
    height: 100vh; 
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--grey-whiteBackGround-7);
  }

  a{
    color: var(--grey-blackBackGround-1);
  }

  /* .label{
    display: flex;
    height: 15%;
    width: 100%;
    justify-content: center;
    align-items:end
  } */




.about-labelTitle{
    margin-bottom: 3px;
    font-family: "Meiryo";
    text-shadow: -1px 0 3px #217aa2, 0 1px 3px #217aa2, 1px 0 3px #217aa2, 0 -1px 3px #217aa2;
    font-weight: 700;
    font-size: 30px;
    color: var(--white);

}

  .settings-main{
    height: 85%;
    width: 80%;

    position:relative;
    margin: 1%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    padding: 4px 12px;
    border: 1px solid var(--grey-blackBackGround-7);
    box-shadow: 1px 1px 2px var(--grey-blackBackGround-7), -1px -1px 2px var(--grey-blackBackGround-7);

    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .about-submit{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .settings-button{
    width: 40px;
    height: 40px;
    padding: 4px 0px;
    border-radius: 5px;
    color: var(--grey-blackBackGround-8);
    background-color: var(--grey-whiteBackGround-6);
    font-size: 16px;
    margin-bottom: 10px;
    box-shadow: 1px 1px 2px var(--grey-blackBackGround-9), -1px -1px 2px var(--grey-blackBackGround-9);
    border: 1px solid var(--grey-blackBackGround-8);
  }

  .settings-button:active {
    box-shadow: inset 1px 1px 2px var(--grey-blackBackGround-7), inset -1px -1px 2px var(--grey-blackBackGround-7);
    background-color: var(--grey-blackBackGround-7); /* 假定按下去时背景色变深 */
  }

  .settings-button-select {
    width: 40px;
    height: 40px;
    padding: 4px 0px;
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 10px;
    box-shadow: 1px 1px 2px var(--grey-blackBackGround-9), -1px -1px 2px var(--grey-blackBackGround-9);
    border: 1px solid var(--grey-blackBackGround-8);
    color: var(--grey-whiteBackGround-7);
    background-color: var(--grey-blackBackGround-1);
  }

  .settings-buttonGroup{
    padding: 4px ;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .settings-label{
    display: flex;
    align-items: center;
    padding: 0px 8px 0px 0px; 
  }

  .settings-namazuGroup{
    gap: 10px;
    padding: 4px 50px;
    display: flex;
    flex-direction: column;
  }


.settings-flex-row{
    display: flex;
    flex-direction: row;
}

.settings-flex-column{
  display: flex;
  flex-direction: column;
}

.settings-small-font{
  font-size: 15px;
}


.settings-titleArea{
    top: 5%;
    left: 1%;
    position:relative;
    font-family: "Meiryo";
    font-weight: 300;
    font-size: 20px;
    font-weight: 700;
    color: var(--grey-blackBackGround-1);
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.about-space{
  margin-left: 50px;
  margin-right: 50px;
}

.about-returnArrow{
  position: absolute;
  top: 10px;
  left: 10px;


  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 27px;
  padding: 4px 12px;
  height: 50px;
  color: var(--grey-blackBackGround-2);
  transform: scale(1.5);
}

.settings-saveButton{
  height: 100%;
  width: 30%;
  border: 1px solid var(--grey-blackBackGround-7);
  box-shadow: 1px 1px 2px var(--grey-blackBackGround-7), -1px -1px 2px var(--grey-blackBackGround-7);

  font-family: "Meiryo";
  font-weight: 300;
  font-size: 20px;
  font-weight: 700;
  color: var(--grey-whiteBackGround-7);
}
.settings-saveButton:active {
  box-shadow: inset 1px 1px 2px var(--grey-blackBackGround-7), inset -1px -1px 2px var(--grey-blackBackGround-7);
  background-color: var(--grey-blackBackGround-7); /* 假定按下去时背景色变深 */
}

 /* 整个滚动条 */
 ::-webkit-scrollbar {
    width: 12px;               /* 滚动条宽度 */
    background-color: var(--grey-blackBackGround-95); /* 滚动条背景颜色 */
  }
  
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #888;    /* 滑块背景颜色 */
    border-radius: 6px;        /* 滑块圆角大小 */
    border: 2px solid var(--grey-blackBackGround-95); /* 滑块边框 */
  }
  
  /* 滚动条滑块悬停样式 */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;    /* 滑块悬停时的背景色 */
  }
  
  /* 滚动条轨道样式 */
  ::-webkit-scrollbar-track {
    background-color: var(--grey-blackBackGround-95); /* 轨道的背景颜色 */
    border-radius: 6px;        /* 轨道圆角大小 */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); /* 轨道内阴影 */
  }
  
  /* 滚动条轨道区域，未使用 */
  ::-webkit-scrollbar-track-piece {
    background-color: none;    /* 未使用滚动条轨道颜色 */
  }
  
  /* 滚动条按钮（上下箭头） */
  ::-webkit-scrollbar-button {
    display: none;             /* 通常不需要显示滚动条按钮 */
  }

  .change-password-body {
    padding: 20px;
}

.change-password-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
}

.change-password-container h2 {
    margin-bottom: 24px;
}

.password-input-group {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    gap: 12px;
}

.password-label {
    min-width: 100px;
    text-align: right;
}

.password-input-group input {
    flex: 1;
    max-width: 300px;
}

.change-password-container button {
    margin-left: 112px; /* 100px label width + 12px gap */
}

.change-password-container input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.change-password-container button{
  width: 30%;
  border: 1px solid var(--grey-blackBackGround-7);
  box-shadow: 1px 1px 2px var(--grey-blackBackGround-7), -1px -1px 2px var(--grey-blackBackGround-7);

  font-family: "Meiryo";
  font-weight: 300;
  font-size: 20px;
  font-weight: 700;
  color: var(--grey-whiteBackGround-7);
}
.change-password-container button:active {
  box-shadow: inset 1px 1px 2px var(--grey-blackBackGround-7), inset -1px -1px 2px var(--grey-blackBackGround-7);
  background-color: var(--grey-blackBackGround-7); /* 假定按下去时背景色变深 */
}

.center{
  display: flex;
  justify-content: space-around;
}