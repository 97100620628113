.index {
    margin: 0;
    display:table;
    padding-top:10px;
    padding-right:10px;
    padding-bottom:10px;
    padding-left:10px;
    width:100%;
    height:100vh;
  }

  * {
    box-sizing: border-box;
  }
  
  .main{
    display:table-cell;
    height:100%;
    /* width:85%; */
  }


  .tagPage{
    width:100%;
    height: 10%;
    position:relative;
    padding-right:2px;
    padding-bottom:1px;
    border: 1px solid var(--grey-blackBackGround-8);
    box-shadow: 1px 1px 2px var(--grey-blackBackGround-9), -1px -1px 2px var(--grey-blackBackGround-9);
  }
  .tagContainer{
    background-color: var(--grey-whiteBackGround-7);
    height: 100%;
    width: 100%;
    position: relative;
    font-weight: 700;
    color: var(--black);
    font-size: 21px;
    text-align: center;
    letter-spacing: 0;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden;
    justify-content:space-between;
  }

  .click-animation {
    transition: transform 0.1s ease-in-out;
  }
  
  .click-animation:active {
    transform: scale(0.9);
  }


  .tagPage .text-tag2 {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 4px 12px;
      height: 60%;
      color: var(--grey-blackBackGround-2);
      transform: scale(1.25);
  }
  

  .text-tag-select {
    position: relative;
    margin: 1%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 27px;
    padding: 4px 12px;
    height: 60%;
    border: 1px solid var(--grey-blackBackGround-8);
    box-shadow: 1px 1px 2px var(--grey-blackBackGround-9), -1px -1px 2px var(--grey-blackBackGround-9);
    color: var(--grey-whiteBackGround-7);
    background-color: var(--grey-blackBackGround-1);
  }

  .text-tag-unselect {
    position: relative;
    margin: 1%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 27px;
    padding: 4px 12px;
    height: 60%;
    border: 1px solid var(--grey-blackBackGround-8);
    box-shadow: 1px 1px 2px var(--grey-blackBackGround-9), -1px -1px 2px var(--grey-blackBackGround-9);
    color: var(--grey-blackBackGround-8);
    background-color: var(--grey-whiteBackGround-6);
  }

  .tagPage .text-arrow {
    position: relative;
    margin: 1%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 27px;
    padding: 4px 12px;
    color: var(--grey-blackBackGround-8);
    height: 60%;
  }

  .chatPage{
    display:table;
    top: 5px;
    width:100%;
    padding-right:2px;
    padding-top:1px;
    border: 1px solid var(--grey-blackBackGround-8);
    box-shadow: 1px 1px 2px var(--grey-blackBackGround-9), -1px -1px 2px var(--grey-blackBackGround-9);
  }

  .chat-container {
    display: table;
    width: 100%;
    height: 100%;
    border: 1px solid #000;
    padding: 5px;

    background-color: var(--grey-whiteBackGround-7);
  }

  .filters{
    height: 50px;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    /* overflow-x:hidden; */
    overflow-y:hidden;
    text-overflow:ellipsis;
  }


  .filterIcon{
    transform: scale(1.5);
    margin: 1.2%;
  }



.filter-divider {
    color: #666;
    margin: 0 4px;
}

.filter-close {
    cursor: pointer;
    font-weight: bold;
    padding-top: 6px;
}

.filter-content {
    margin-right: 4px;
}

  .filter{
    width:max-content;
    position: relative;
    margin: 0.5%;
    display: flex;
    padding: 3px 12px;
    border: 1px solid var(--grey-blackBackGround-7);
    box-shadow: 1px 1px 2px var(--grey-blackBackGround-7), -1px -1px 2px var(--grey-blackBackGround-7);
    background-color: var(--grey-whiteBackGround-7);
    color: var(--grey-blackBackGround-1);
    font-weight: 500;
    overflow-x:hidden;
    overflow-y:hidden;
    white-space: nowrap;
    align-items: center;
    gap: 4px;
}

.filterSpan{
  font-weight: 600; 
  color: var(--red)
}

  .messageBoard{
    height: 90%;
    overflow-y: auto;
  }

  .message {
    padding: 5px;
    border-radius: 5px;
    margin: 5px;
    background-color: var(--grey-blackBackGround-9);
    color: var(--grey-blackBackGround-2);
    display: flex;        /* 使用Flexbox布局 */
    justify-content: space-between;
    
  }

  .report{
    color: var(--grey-blackBackGround-7);
  }

  .black-user{
    color: var(--grey-blackBackGround-7);
    transform: scale(0.8);
  }

  .ff14-font{
    font-family: "Meiryo";
    font-size: 15px;
    text-shadow: -1px 0 3px #217aa2, 0 1px 3px #217aa2, 1px 0 3px #217aa2, 0 -1px 3px #217aa2;
    font-weight: 300;
    color: #E2EBF5;
  }

  .system-user{
    font-family: "Meiryo";
    font-size: 15px;
    /* text-shadow: -1px 0 3px #217aa2, 0 1px 3px #217aa2, 1px 0 3px #217aa2, 0 -1px 3px #217aa2; */
    font-weight: 300;
    color: #E2EBF5;
  }

  .warn-user{
    font-family: "Meiryo";
    font-size: 15px;
    text-shadow: -1px 0 3px #217aa2, 0 1px 3px #217aa2, 1px 0 3px #217aa2, 0 -1px 3px #217aa2;
    font-weight: 300;
    color: #E2EBF5;
  }

  .parent-user{
    display:flex;
    font-weight: 500;
    font-family: "Meiryo";
    font-size: 15px;
    width: 100%;
  }

  .right-parent{
      display:flex;
  }

  .poster{
    color: #E2EBF5;
    margin-right: 5px;
    margin-top: 3px;
  }

  .poster-shadow{
    text-shadow: -1px 0 3px #1e9131, 0 1px 3px #1e9131, 1px 0 3px #1e9131, 0 -1px 3px #1e9131;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 0;
  }

  .poster-content {
    color: #E2EBF5;
    margin-right: 5px;
    margin-top: 3px;
    white-space: pre-wrap; 
    word-wrap: break-word; 
    flex: 1; 
}

  .region-user{
    text-shadow: -1px 0 3px #21a237, 0 1px 3px #21a237, 1px 0 3px #21a237, 0 -1px 3px #21a237;
    color: #E2EBF5;
  }
  .teamup-user{
    text-shadow: -1px 0 3px #217aa2, 0 1px 3px #217aa2, 1px 0 3px #217aa2, 0 -1px 3px #217aa2;
    color: #E2EBF5;
  }
  .translate-user{
    text-shadow: -1px 0 3px #217aa2, 0 1px 3px #217aa2, 1px 0 3px #217aa2, 0 -1px 3px #217aa2;
    color: #E2EBF5;
  }

  .htmlpage-row{
    width: 100%;
    height: 90%;
    min-height: 100px;
    display: block;
    flex-wrap: wrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }


  .users{
    top: 10px;
    width:15%;
    display:table-cell;
    height:100%;
    background-color: var(--grey-whiteBackGround-7);
    border: 1px solid var(--grey-blackBackGround-8);
    box-shadow: 1px 1px 2px var(--grey-blackBackGround-9), -1px -1px 2px var(--grey-blackBackGround-9);
    flex-wrap: wrap;
  }

  .user{
    width: 90%;
      padding: 5px;
      border-radius: 5px;
      margin: 5px;
      background-color: var(--grey-blackBackGround-9);
      color: var(--grey-blackBackGround-2);
      display: flex;        /* 使用Flexbox布局 */
      justify-content: space-between;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }


  /* 整个滚动条 */
::-webkit-scrollbar {
  width: 12px;               /* 滚动条宽度 */
  background-color: var(--grey-blackBackGround-95); /* 滚动条背景颜色 */
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: #888;    /* 滑块背景颜色 */
  border-radius: 6px;        /* 滑块圆角大小 */
  border: 2px solid var(--grey-blackBackGround-95); /* 滑块边框 */
}

/* 滚动条滑块悬停样式 */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;    /* 滑块悬停时的背景色 */
}

/* 滚动条轨道样式 */
::-webkit-scrollbar-track {
  background-color: var(--grey-blackBackGround-95); /* 轨道的背景颜色 */
  border-radius: 6px;        /* 轨道圆角大小 */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); /* 轨道内阴影 */
}

/* 滚动条轨道区域，未使用 */
::-webkit-scrollbar-track-piece {
  background-color: none;    /* 未使用滚动条轨道颜色 */
}

/* 滚动条按钮（上下箭头） */
::-webkit-scrollbar-button {
  display: none;             /* 通常不需要显示滚动条按钮 */
}


.input-container {
  display:flex;
    top: 5px;
    height: 5%;
    width:100%;
    padding-right:2px;
    padding-top:1px;
    border: 1px solid var(--grey-blackBackGround-8);
    box-shadow: 1px 1px 2px var(--grey-blackBackGround-9), -1px -1px 2px var(--grey-blackBackGround-9);}

.chat-input {
  background-color: var(--grey-whiteBackGround-7);
  height: 100%;
  width: 100%;
  position: relative;
  font-weight: 700;
  color: #fff;
  font-size: 21px;
  letter-spacing: 0;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  justify-content:space-between;
  border: 1px solid #333;
  padding: 8px 40px 8px 12px;
}

.chat-input::placeholder {
  color: #888;
}

