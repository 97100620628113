

.about-body {
    margin: 0; 
    height: 100vh; 
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--grey-whiteBackGround-7);
  }

  a{
    color: var(--grey-blackBackGround-1);
  }

  /* .label{
    display: flex;
    height: 15%;
    width: 100%;
    justify-content: center;
    align-items:end
  } */

.about-logo{
    height: 60%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.about-logo2{
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-logoImg{
    width: 70%;
}

.about-labelTitle{
    margin-bottom: 3px;
    font-family: "Meiryo";
    text-shadow: -1px 0 3px #217aa2, 0 1px 3px #217aa2, 1px 0 3px #217aa2, 0 -1px 3px #217aa2;
    font-weight: 700;
    font-size: 30px;
    color: var(--white);

}

  .about-main{
    height: 85%;
    width: 80%;

    position:relative;
    margin: 1%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    padding: 4px 12px;
    border: 1px solid var(--grey-blackBackGround-7);
    box-shadow: 1px 1px 2px var(--grey-blackBackGround-7), -1px -1px 2px var(--grey-blackBackGround-7);
  }

  .about-submit{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }


.about-return{
  height: 30%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}



.about-titleArea{
    top: 5%;
    left: 1%;
    position:relative;
    font-family: "Meiryo";
    font-weight: 300;
    font-size: 20px;
    font-weight: 700;
    color: var(--grey-blackBackGround-1);
    display: flex;
    justify-content: center;
}

.about-issueArea{
  top: 5%;
  left: 1%;
  position:relative;
  font-family: "Meiryo";
  font-weight: 300;
  font-size: 20;
  font-weight: 700;
  color: var(--grey-blackBackGround-1);
  display: flex;
  justify-content: center;
}

.about-textArea{
  height: 40%;
  top: 5%;
  left: 1%;
  position:relative;
  font-family: "Meiryo";
  font-weight: 300;
  font-size: 20;
  font-weight: 700;
  color: var(--grey-blackBackGround-1);
  display: flex;
  justify-content: center;
}


.about-space{
  margin-left: 50px;
  margin-right: 50px;
}

.about-returnArrow{
  position: absolute;
  top: 10px;
  left: 10px;


  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 27px;
  padding: 4px 12px;
  height: 50px;
  color: var(--grey-blackBackGround-2);
  transform: scale(1.5);
}

 /* 整个滚动条 */
 ::-webkit-scrollbar {
    width: 12px;               /* 滚动条宽度 */
    background-color: var(--grey-blackBackGround-95); /* 滚动条背景颜色 */
  }
  
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #888;    /* 滑块背景颜色 */
    border-radius: 6px;        /* 滑块圆角大小 */
    border: 2px solid var(--grey-blackBackGround-95); /* 滑块边框 */
  }
  
  /* 滚动条滑块悬停样式 */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;    /* 滑块悬停时的背景色 */
  }
  
  /* 滚动条轨道样式 */
  ::-webkit-scrollbar-track {
    background-color: var(--grey-blackBackGround-95); /* 轨道的背景颜色 */
    border-radius: 6px;        /* 轨道圆角大小 */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); /* 轨道内阴影 */
  }
  
  /* 滚动条轨道区域，未使用 */
  ::-webkit-scrollbar-track-piece {
    background-color: none;    /* 未使用滚动条轨道颜色 */
  }
  
  /* 滚动条按钮（上下箭头） */
  ::-webkit-scrollbar-button {
    display: none;             /* 通常不需要显示滚动条按钮 */
  }

  /* .about-navigation {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 20px 0;
} */

.about-navButton {
    padding: 8px 24px;
    font-family: "Meiryo";
    font-size: 18px;
    font-weight: 700;
    color: var(--grey-whiteBackGround-7);
    background-color: var(--white);
    border: 1px solid var(--grey-blackBackGround-7);
    cursor: pointer;
    transition: all 0.3s ease;
}

.about-navButton.active {
    background-color: var(--grey-blackBackGround-7);
    color: var(--white);
}

/* .about-content {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
} */

.about-issues {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.about-issue-form {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  border-top: 1px solid var(--grey-blackBackGround-7);
  padding: 20px;
  transition: all 0.3s ease;
  z-index: 100;
  background-color: var(--grey-whiteBackGround-6);
}

.about-issue-form::before {
  content: '';
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(to top, rgba(255,255,255,0.3), rgba(255,255,255,0));
  opacity: 0;
  transition: opacity 0.3s ease;
}

.about-issue-form:hover .about-issue-form-container {
  height: 180px; /* 展开后显示完整高度 */
}

.about-issue-form:hover::before {
  opacity: 1;
}

.about-issue-form-container {
  position: relative;
  height: 40px; /* 默认只显示一小部分输入框 */
  overflow: hidden;
  transition: all 0.3s ease;
}

.about-issue-form:hover .about-issue-form-container {
  height: 200px; /* 展开后的高度 */
}

.about-navigation {
  width: 80%;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

/* Issue 项目样式 */
.about-issue-item {
  border: 1px solid var(--grey-blackBackGround-7);
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.about-issue-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--grey-blackBackGround-95);
}

.about-issue-date {
  font-size: 14px;
  color: var(--grey-blackBackGround-4);
  font-family: "Meiryo";
}

.about-issue-status {
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 600;
  font-family: "Meiryo";
}

.about-issue-status.solved {
  background-color: #4CAF50;
  color: white;
}

.about-issue-status.pending {
  background-color: #FFC107;
  color: black;
}

.about-issue-text {
  font-size: 16px;
  line-height: 1.5;
  color: var(--grey-blackBackGround-1);
  margin-bottom: 12px;
  font-family: "Meiryo";
}

.about-issue-reply {
  background-color: var(--grey-whiteBackGround-4);
  border-left: 4px solid var(--grey-blackBackGround-7);
  padding: 12px;
  margin-top: 12px;
  font-size: 14px;
  color: var(--grey-blackBackGround-4);
  font-family: "Meiryo";
}

/* Issues 列表容器样式 */
.about-issues-list {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  border-radius: 4px;
}


.about-input {
  width: 100%;
  height: 120px; /* 输入框实际高度 */
  padding: 12px;
  border: 1px solid var(--grey-blackBackGround-7);
  border-radius: 4px;
  font-family: "Meiryo";
  font-size: 14px;
  resize: none;
  transition: all 0.3s ease;
  background-color: var(--grey-whiteBackGround-9);
  color: var(--grey-blackBackGround-1);
}

.about-issue-form:hover .about-input {
  opacity: 1;
}


.about-saveButton {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  min-width: 120px;
  padding: 8px 24px;
  /* background-color: var(--white);
  color: var(--grey-blackBackGround-7); */
  border: 1px solid var(--grey-blackBackGround-7);
  border-radius: 4px;
  font-family: "Meiryo";
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0; /* 默认隐藏按钮 */
  pointer-events: none; /* 默认不可点击 */
}

.about-issue-form:hover .about-saveButton {
  opacity: 1;
  pointer-events: auto; /* 恢复可点击状态 */
}

/* 容器样式更新 */
.about-issues {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
/* Donate 页面样式更新 */
.about-donate {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow-y: auto;
}

.about-donate-header {
  text-align: center;
  margin-bottom: 20px;
}

.about-donate-header h2 {
  color: var(--grey-blackBackGround-1);
  font-size: 24px;
  margin-bottom: 10px;
  font-family: "Meiryo";
}

.about-donate-header p {
  color: var(--grey-blackBackGround-4);
  font-size: 16px;
  line-height: 1.5;
  font-family: "Meiryo";
}

.about-donate-methods {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}

.about-donate-method {
  /* background-color: var(--white); */
  border: 1px solid var(--grey-blackBackGround-7);
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  max-width: 600px;
  text-align: center;
}


.about-donate-supporters {
  width: 100%;
  max-width: 600px;
}

.about-donate-supporters h3 {
  color: var(--grey-blackBackGround-1);
  font-size: 20px;
  margin-bottom: 15px;
  text-align: center;
  font-family: "Meiryo";
}

.about-donate-supporters-list {
  border: 1px solid var(--grey-blackBackGround-7);
  border-radius: 8px;
  padding: 15px;
}

.about-donate-supporter {
  padding: 12px;
  border-bottom: 1px solid var(--grey-blackBackGround-95);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.about-donate-supporter:last-child {
  border-bottom: none;
}

.about-donate-supporter-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.about-donate-supporter-name {
  color: var(--grey-blackBackGround-1);
  font-family: "Meiryo";
  font-weight: 600;
  font-size: 16px;
}

.about-donate-supporter-date {
  color: var(--grey-blackBackGround-4);
  font-size: 14px;
  font-family: "Meiryo";
}

.about-donate-supporter-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
}

.about-donate-supporter-amount {
  color: #4CAF50;
  font-weight: 600;
  font-family: "Meiryo";
}

.about-donate-supporter-text {
  color: var(--grey-blackBackGround-4);
  font-size: 14px;
  font-style: italic;
  font-family: "Meiryo";
  flex: 1;
  text-align: right;
}

/* 支付方式样式 */
.about-donate-payment-options {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin: 20px 0;
  flex-wrap: wrap;
}

.about-donate-payment-logo {
  position: relative;
  width: 60px;
  height: 60px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.about-donate-payment-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: grayscale(30%);
  transition: filter 0.3s ease;
}

.about-donate-payment-logo:hover img {
  filter: grayscale(0%);
  transform: scale(1.05);
}

.about-donate-qr-popup {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  width: 200px;
  text-align: center;
  margin-bottom: 12px;
  border: 1px solid var(--grey-blackBackGround-7);
}

.about-donate-qr-popup::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.about-donate-qr-popup img {
  width: 100%;
  height: auto;
  margin-bottom: 8px;
}

.about-donate-qr-label {
  display: block;
  color: var(--grey-blackBackGround-4);
  font-size: 14px;
  font-family: "Meiryo";
}








.about-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* 添加新样式 */
.about-link-button {
  background: none;
  border: none;
  color: var(--grey-blackBackGround-1);
  font-family: "Meiryo";
  font-size: inherit;
  cursor: pointer;
  padding: 0;
  text-decoration: underline;

  margin-bottom: 3px;
  font-family: "Meiryo";
  text-shadow: -1px 0 3px #217aa2, 0 1px 3px #217aa2, 1px 0 3px #217aa2, 0 -1px 3px #217aa2;
  font-weight: 700;
  font-size: 30px;
}

.about-changelog {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
}

.about-changelog-title {
  color: var(--grey-blackBackGround-1);
  font-size: 24px;
  margin-bottom: 20px;
  font-family: "Meiryo";
  text-align: center;
}

.about-changelog-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.about-changelog-item {
  border: 1px solid var(--grey-blackBackGround-7);
  border-radius: 8px;
  padding: 16px;
}

.about-changelog-version {
  font-size: 18px;
  font-weight: bold;
  color: var(--grey-blackBackGround-1);
  margin-bottom: 4px;
}

.about-changelog-date {
  font-size: 14px;
  color: var(--grey-blackBackGround-4);
  margin-bottom: 12px;
}

.about-changelog-changes {
  list-style-type: disc;
  margin-left: 20px;
  color: var(--grey-blackBackGround-1);
}

.about-changelog-changes li {
  margin-bottom: 8px;
}