* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.login-back{
    min-height: 100vh;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: var(--grey-whiteBackGround-7);
}

.login-beian{
    align-items: center;
    justify-content: center;
}
.login-beianlink{
    align-items: center;
    justify-content: center;
    color: rgba(255,255,255,0.4);
    font-size: small;
    display: flex;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.6), -2px -2px 4px rgba(0,0,0,0.2);
    text-decoration: none;
}

.login-body {
    justify-content: center;
    min-height: 100vh;
    align-items: center;
    display: flex;
}

.login-form-wrapper {
    display: flex;
    flex-direction: column; 
    gap: 10px; 
}

.login-login {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

.login-labelSelected{
    color: var(--white);
}

.login-labelunSelected{
    color: var(--grey-blackBackGround-7);
}
  

input[type="username"],
input[type="password"] {
    width: 250px;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    font-size: 16px;
    margin-bottom: 10px;
}

select{
    width: 250px;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    font-size: 16px;
    margin-bottom: 10px;
}

label{
    font-family: "Meiryo";
    font-size: 15px;
    text-shadow: -1px 0 3px #217aa2, 0 1px 3px #217aa2, 1px 0 3px #217aa2, 0 -1px 3px #217aa2;
    font-weight: 300;
    color: #E2EBF5;
}

.login-button {
    width: 250px; 
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: #0084ff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;

    font-family: "Meiryo";
    font-size: 16px;
    text-shadow: -1px 0 3px #217aa2, 0 1px 3px #217aa2, 1px 0 3px #217aa2, 0 -1px 3px #217aa2;
    font-weight: 300;
    color: #E2EBF5;
}

.login-button:hover {
    background-color: #005fcc;
}

.login-button:focus {
    outline: none;
}

.login-registerButton {
    background-color: #e7e7e7;
}

.login-registerButton:hover {
    background-color: #d0d0d0;
}

.login-title{
    display: flex; 
    justify-content:space-between
}

.login-title h1 {
    margin-right: 15px;
    margin-left: 30px;
    margin-bottom: 20px;
    font-family: "Meiryo";
    text-shadow: -1px 0 3px #217aa2, 0 1px 3px #217aa2, 1px 0 3px #217aa2, 0 -1px 3px #217aa2;
    font-weight: 300;
  }

  .login-player{
    position: absolute;
    left: 0;
    top: 0;
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: 20px;
    font-family: "Meiryo";
    text-shadow: -1px 0 3px #217aa2, 0 1px 3px #217aa2, 1px 0 3px #217aa2, 0 -1px 3px #217aa2;
    font-weight: 300;
    color: #E2EBF5;
  }

  .login-small{
    font-family: "Meiryo";
    font-size: 13px;
    text-shadow: -1px 0 3px #217aa2, 0 1px 3px #217aa2, 1px 0 3px #217aa2, 0 -1px 3px #217aa2;
    font-weight: 300;
    color: #E2EBF5;
  }

