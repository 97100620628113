.points-container {
    position: absolute;  /* Absolute positioning within its parent container */
    right: 14px;
    bottom: 17px;
    display: flex;
    flex-direction: column;  /* Stack rows vertically */
    align-items: flex-end;   /* Align rows to the right */
  }
  
  .points-grid {
    display: flex;
    flex-direction: column;  /* Stack points in columns */
  }
  
  .point-row {
    display: flex;
    height: 8px;
    width: 8px;
    justify-content: flex-end;  /* Align points to the left of each row */
    margin: 0;                    /* Remove any margin between points */
  }
  
  .point {
    font-size: 10px;    /* Font size for the points */
    margin: 0 2px;      /* Small horizontal margin to make the points as close as possible */
    color: var(--grey-blackBackGround-4);
  }