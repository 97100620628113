.label{
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: 20px;
    font-family: "Meiryo";
    text-shadow: -1px 0 3px #217aa2, 0 1px 3px #217aa2, 1px 0 3px #217aa2, 0 -1px 3px #217aa2;
    font-weight: 300;
    color: var(--white);
}

.body {
    margin: 0; /* 移除默认的margin */
    height: 100vh; /* 使body高度占满整个视口高度 */
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    background-color: var(--grey-whiteBackGround-7);
}
